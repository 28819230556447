.spinner {
  position: relative;
  height: 100px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .spinner {
    animation: spinner-spin infinite 1s linear;
  }
}

@keyframes spinner-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Page Loader ================================= */
.page-loader-wrapper {
  z-index: 99999999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #eee;
  overflow: hidden;
  text-align: center;
  display: none;
}
.page-loader-wrapper p {
  font-size: 13px;
  font-weight: bold;
  color: #444;
}
.page-loader-wrapper .loader {
  position: relative;
  top: calc(50% - 100px);
}

.App {
  position: absolute;
  background-color: #808080;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Roboto", Arial, Tahoma, sans-serif;
  color: white;
}

.form-group input {
  padding: 6px;
  border: none;
  margin-bottom: 10px;
  background-color: white;
  opacity: 0.8;
  border-radius: 3px;
}

.btn {
  border: none;
  padding: 5px;
  border-radius: 2px;
  padding: 6px 20px 6px 20px;
  cursor: pointer;
  font-family: "Roboto", Arial, Tahoma, sans-serif;
}

.btn-success {
  background-color: #28a745;
  color: white;
}

.btn-danger {
  background-color: #dc3545;
  color: white;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn-default {
  background-color: #f8f9fa;
  color: black;
}
