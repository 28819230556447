.QrReader {
  position: relative;
  width: 300px;
  height: 300px;
}

.info {
  position: absolute;
  top: 10px;
  font-size: 1.5rem;
}

.guestview {
  display: flex;
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  color: black;
}

.guestview img {
  width: 65px;
  height: 80px;
  border-radius: 5px;
  margin-right: 10px;
}

.guestview div {
  width: 100%;
}

.guestview button {
  width: 100%;
  margin-top: 5px;
}
